export const validatePincode = (value: string) => {
    if (!value) {
        return '';
    }
    const hasNonDigits = /[^0-9]/.test(value);
    if (hasNonDigits) {
        return 'Pincode must contain only digits.';
    }
     else if (value.length > 6) {
        return 'Pincode must be exactly 6 digits.';
    }
    return '';
};
