import React, { useEffect, useState } from 'react';
import './sidenav.scss';
import { IconContext } from 'react-icons';
import * as AiIcons from 'react-icons/ai';

type props = {
  selected?: string;
};

const NavBar: React.FC<props> = ({ selected }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const SidebarData = require('./sidebarData').default;
  const expandSidebar = () => {
    const x = document.getElementById('myLinks');
    if (x) {
      x.style.display = x.style.display === 'block' ? 'none' : 'block';
    }
  };

  return (
    <>
      {windowWidth > 600 && (
        <IconContext.Provider value={{ color: '#fff' }}>
          <div className='sidebar'>
            <a className="navbar-brand" href="https://trackyo.in">
              <img src="app_logo.png" alt="trackyo_logo" style={{ width: '6rem', margin: '20px' }} />
            </a>
            {SidebarData.map((item: any, index: number) => {
            
              return (
                <a key={index} href={item.path} className={item.title === selected ? 'highlight' : 'sidebar-section'}>
                  {item.icon}&nbsp;
                  <span>{item.title}</span>
                </a>
              );
            })}
          </div>
        </IconContext.Provider>
      )}
      {windowWidth <= 600 && (
        <div className='topnav'>
          <div id='myLinks'>
            <a className="navbar-brand" href="https://trackyo.in">
              <img src="app_logo.png" alt="trackyo_logo" style={{ width: '6rem' }} />
            </a>
            {SidebarData.map((item: any, index: number) => (
              <a key={index} href={item.path} className={item.cName}>
                {item.icon}&nbsp;
                <span>{item.title}</span>
              </a>
            ))}
          </div>
          <AiIcons.AiOutlineBars onClick={() => expandSidebar()} />
        </div>
      )}
    </>
  );
};

export default NavBar;
