import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Table, TableBody, TableCell, TableRow } from '@mui/material';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import { toast } from 'react-toastify';

interface AddDocumentModalProps {
    isDocumentOpen: boolean;
    handleClose: () => void;
}

const AddDocumentModal: React.FC<AddDocumentModalProps> = ({ isDocumentOpen, handleClose }) => {
    const [file, setFile] = useState<{ [key: string]: File | null }>({});
    const [icons, setIcons] = useState<{ [key: string]: 'upload' | 'view' }>({
        Aadhar: 'upload',
        licence: 'upload',
    });
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [docTypeToDelete, setDocTypeToDelete] = useState<string | null>(null);
    const maxFileSize = 1 * 1024 * 1024;
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            if (selectedFile.size > maxFileSize) {
                toast.error('File size exceeds 1MB.');
                return;
            }
            if (!allowedFileTypes.includes(selectedFile.type)) {
                toast.error('Invalid file type.');
                return;
            }
            setFile(prev => ({ ...prev, [type]: selectedFile }));
            setIcons(prev => ({ ...prev, [type]: 'view' }));
        }
    };

    const handleIconClick = (type: string, action: 'view' | 'remove') => {
        if (action === 'remove') {
            setDocTypeToDelete(type);
            setOpenDeleteModal(true);
        } else if (action === 'view' && file[type]) {
        }
    };
   const handleConfirmDelete = () => {
        if (docTypeToDelete) {
            setFile(prev => ({ ...prev, [docTypeToDelete]: null }));
            setIcons(prev => ({ ...prev, [docTypeToDelete]: 'upload' }));
            setOpenDeleteModal(false);
            setDocTypeToDelete(null);
        }
    };
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setDocTypeToDelete(null);
    };
    const renderFileInput = (label: string, type: string) => (
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <p style={{ fontSize: '17px', margin: 0, marginRight: '10px' }}>{label}</p>
            <input
                accept="*"
                style={{ display: 'none' }}
                id={`${type}-file`}
                type="file"
                onChange={(e) => handleFileChange(e, type)}
            />
            <label htmlFor={`${type}-file`}>
                <Tooltip title="Upload File">
                    <IconButton color="primary" component="span">
                        {icons[type] === 'upload' && <UploadIcon />}
                    </IconButton>
                </Tooltip>
            </label>
            {icons[type] === 'view' && (
                <>
                    <Tooltip title="View File">
                        <span
                            style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
                            onClick={() => handleIconClick(type, 'view')}
                        >
                            View
                        </span>
                    </Tooltip>
                    <Tooltip title="Remove File">
                        <IconButton sx={{ color: 'red' }} component="span" onClick={() => handleIconClick(type, 'remove')}>
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </div>
    );

    return (
        <Dialog open={isDocumentOpen} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>
                <Heading title="Add Document" closeIcon="true" onClose={handleClose} />
            </DialogTitle>
            <DialogContent>
            <Table style={{ border: '3px solid black' }}>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                {renderFileInput('Aadhar Card', 'Aadhar')}
                            </TableCell>
                            <TableCell>
                                {renderFileInput('Licence', 'licence')}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <ConfirmationPopup
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    onConfirm={handleConfirmDelete}
                    title="Confirm Deletion"
                    message={`Are you sure you want to delete this ${docTypeToDelete?.replace('_', ' ')}?`}
                />
            </DialogContent>
        </Dialog>
    );
};

export default AddDocumentModal;
