import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Routes ,Route } from 'react-router-dom';
import './Components/globalVariables.js';
import LoginPage from './Components/Login/login.tsx';
import Footer from './Components/Footer/footer.tsx';
import Trips from './Pages/Trips/trips.tsx';
import Dashboard from './Pages/Dashboard/dashboard.tsx';
// import Users from './Pages/Users/users.tsx';
import Vehicles from './Pages/Vehicles/vehicle.tsx';
import Drivers from './Pages/Drivers/drivers.tsx';
function App() {
  return (
    <div className="App">
     <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<LoginPage />} />
        <Route path ="/trips" element={<Trips/>} />
        {/* <Route path ="/users" element={<Users/>} /> */}
        <Route path = "/vehicles" element ={<Vehicles/>} />
        <Route path = "/drivers" element ={<Drivers/>} />
      </Routes>
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} closeOnClick pauseOnHover />
      <Footer/>
    </div>
  );
}
export default App;
