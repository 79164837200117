import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Tooltip ,TextField} from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

  interface DocumentsModal {
   open: boolean;
   onClose: () => void;
  }
  const DocumentsModal: React.FC<DocumentsModal> = ({ open, onClose }) => {
    const [documentList, setDocumentList] = useState<any[]>([]);
    const handleIconClick = (type: string, action: 'view' | 'remove') => {
         if (action === 'view' && file[type]) {
           
        }
    };
  const renderFileInput = (label: string, type: string, expiryDate: string | null) => {
    const document = documentList.find(doc => doc.document_name.replace(' ', '').toLowerCase() === type.toLowerCase() && doc.status === 'uploaded');
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px', width: '180px' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
          />
          {document && (
            <Tooltip title="View File">
              <span
                style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
                onClick={() => handleIconClick(type, 'view')}
              >
                View
              </span>
            </Tooltip>
          )}
        </div>
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: '400px', height: 'auto' } }}>
      <DialogTitle>
        <div style={{ margin: '-13px -24px 20px -24px' }}>
          <Heading title='Documents' closeIcon="true" onClose={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
         <div style= {{marginLeft: '10px', marginTop:'10px'}}>
            {renderFileInput('Aadhar : ', 'aadhar')}
         </div>
         <div style= {{marginLeft: '180px', marginTop:'-33px'}}>
            {renderFileInput('Licence : ','licence')}
         </div>
      </DialogContent>
    </Dialog>
  );
};

export default DocumentsModal;
