import React, {useState, useEffect} from 'react';
import Heading from '../../Components/Heading/heading.tsx';
import '../../Components/Button/button-style.scss';
import {Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, SelectChangeEvent, Box} from '@mui/material';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import {fetchClients, getDriverName , addClientUserMapping, addVehicle , fetchVendors ,addVendorUserMapping} from './action.ts';
import {toast} from 'react-toastify';
import AddDocumentModal from './add-document-modal.tsx';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import {formatVehicleNo} from '../../Utils/VehicleNumberValidation/vehicle-number-validation.tsx';
import {validateMakeYear} from '../../Utils/MakeYearValidation/make-year-validation.tsx';
import {validateModel} from '../../Utils/ModelValidation/model-validation.tsx';

interface AddVehicleModalProps {
  addVehicleModalOpen: boolean;
  handleCloseAddVehicle: () => void;
}
interface Client {
  id: number;
  name: string;
}
interface Vendor {
  id: number;
  name: string; 
}

const AddVehicleModal = ({ addVehicleModalOpen, handleCloseAddVehicle }: AddVehicleModalProps) => {
  const [selectedValue, setSelectedValue] = useState<{ [key: string]: string }>({
    numberPlate: '',
    model: '',
    makeYear: '',
  });
  const [errorClient,setErrorClient] = useState('');
  const [ownerPhoneNumber, setOwnerPhoneNumber] = useState<string>('');
  const [ownerPhoneError, setOwnerPhoneError] = useState<string>('');
  const [driverPhoneNumber, setDriverPhoneNumber] = useState<string>('');
  const [driverPhoneError, setDriverPhoneError] = useState<string>('');
  const [driverName, setDriverName] = useState<string>('');
  const [ownerName,setOwnerName] =  useState<string>('');
  const [ownerId,setOwnerId] = useState(0);
  const [driverId,setDriverId] = useState(0);
  const [userId, setUserId] = useState(0);
  const [apiSucessForOwner,setApiSuccessForOwner] = useState<string>('');
  const [apiSucessForDriver,setApiSuccessForDriver] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [vendorName, setVendorName] = useState<string>('');
  const [clientName, setClientName] = useState<string>('');
  const [clients, setClients] = useState<Client[]>([]);
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [vehicleId, setVehicleId] = useState(0);
  const [selectedClient, setSelectedClient] = useState<{ id: string; label: string }[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<{ id: string; label: string }[]>([]);
  const [vendorId,setVendorId] = useState(0);
  const [fuelType, setFuelType] =  useState('');
  const [driver,setDriver] = useState(0);
  const [owner,setOwner] = useState(0);
  const [error, setError] = useState({
    numberPlate: '',
    makeYear: '',
    model:'',
  });
  const [isFocusedOwner, setIsFocusedOwner] = useState(false);
  const [isFocusedDriver, setIsFocusedDriver] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const handleClose = () => {
    handleCloseAddVehicle();
    setIsDocumentModalOpen(false);
    resetAddVehiclePopup();
    window.location.reload();
  };
  const resetAddVehiclePopup = () => {
    setSelectedValue({
        phoneNumber: '',
        numberPlate: '',
        model: '',
        makeYear: '',
      });
      setOwnerPhoneNumber('');
      setOwnerPhoneError('');
      setDriverPhoneNumber('');
      setDriverPhoneError('');
      setDriverName('');
      setOwnerName('');
      setOwnerId(0);
      setDriverId(0);
      setUserId(0);
      setApiSuccessForOwner('');
      setApiSuccessForDriver('');
      setRole('');
      setVendorName('');
      setClientName('');
      setClients([]);
      setVendors([]);
      setSelectedClient([]);
      setSelectedVendor([]);
      setError({
        numberPlate: '',
        makeYear: '',
        model: '',
      });
      setIsFocusedOwner(false);
      setIsFocusedDriver(false);
      setFuelType('');
      setErrorClient('');
      setDriver(0);
  };
  const handleCancel = () =>{
    resetAddVehiclePopup();
    handleCloseAddVehicle();
  };
  const fuelOptions = [
    { id: 'diesel', label: 'Diesel' },
    { id: 'EV', label: 'EV' },
    { id: 'petrol', label: 'Petrol' },
    { id: 'CNG', label: 'CNG' }
  ];
  const handleFuelTypeChange = (event: SelectChangeEvent<string>) => {
    setFuelType(event.target.value);
  };
  const validateVehicleNo = (vehicleNo) => {
    if (!vehicleNo) {
        return "";
    }
    const replacedSpace = vehicleNo.replace(/ /g, "");
    const pattern = /^([A-Z]{2})(\d{2})([A-Z]{0,2})?(\d{1,4})?$/;
    const match = replacedSpace.match(pattern);
    let errorMessage = '';
    if (match) {
        const [, part1, part2, part3, part4] = match;
        if(part2 && (!part4 || part4.length===0))
        {
          errorMessage = 'Invalid vehicle number format';
        }
        if (part3 && part3.length > 0 &&  (!part4 || part4.length === 0)) {
            errorMessage = 'Invalid vehicle number format';
        }
       } 
      else {
      errorMessage = 'Invalid vehicle number format.';
      }
      if (errorMessage) {
        setError((prevError) => ({
            ...prevError,
            numberPlate: errorMessage,
        }));
      } else {
        setError((prevError) => ({
            ...prevError,
            numberPlate: '',
        }));
      }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setError((prevError) => ({
         ...prevError,
         [name]: '',
    }));
    if(name=== 'makeYear'){
      const error = validateMakeYear(value);
      setError((prevError) => ({
          ...prevError,
          [name]: error,
      }));
     setSelectedValue((prevValue) => ({
          ...prevValue,
          [name]: value,
      }));
      }
    else  if (name === 'numberPlate') {
      const { formatted, error } = formatVehicleNo(value);
        setSelectedValue((prevValue) => ({
            ...prevValue,
            [name]: formatted,
        }));
        setError((prevError) => ({
            ...prevError,
            numberPlate: error,
        }));
    }else if (name === 'model') {
      const error = validateModel(value);
      setError((prevError) => ({
        ...prevError,
        [name]: error,
    }));
      setSelectedValue((prevValue) => ({
           ...prevValue,
           [name]: value,
       }));
    }
  };
  const handlePhoneNumberChange = (event) => {
    const { name, value } = event.target;
    if(name==='driverPhoneNumber'){
      setDriverPhoneNumber(value);
    }
    if(name==='ownerPhoneNumber'){
      setOwnerPhoneNumber(value);
    }
    if(value===''){
    if (name==='ownerPhoneNumber' ) {
      setOwnerPhoneError("");
      setApiSuccessForOwner("false");
      setOwnerName("");
    } 
     if(name==='driverPhoneNumber')
    {
      setDriverPhoneError("");
      setApiSuccessForDriver("false");
      setSelectedClient([{ id: '', label: 'Select Client' }]);
      setErrorClient("");
    }
    return;
  }
    const { errorMessage } = validatePhoneNumber(value);
    if (name === 'ownerPhoneNumber') {
          setOwnerPhoneError(errorMessage);
    } else if (name === 'driverPhoneNumber') {
          setDriverPhoneError(errorMessage);
          setSelectedClient([{ id: '', label: 'Select Client' }]);
          setErrorClient("");
    }
  };
  const handleFocusOwner = () => {
    setIsFocusedOwner(true);
  };
  const handleBlurOwner = () => {
    if (ownerPhoneNumber.length >= 1 && ownerPhoneNumber.length < 10) {
      setOwnerPhoneError('Phone number must be 10 digits');
      setOwnerName(''); 
      setApiSuccessForOwner("false");
      setIsFocusedOwner(false); 
    }  else if (ownerPhoneNumber.length == 10){
      setIsFocusedOwner(false); 
       getDriverName(ownerPhoneNumber, 
      (success) => {
          console.log('User data fetched:', success);
          const user = success.message.user[0];
          const userName = user?.user_name || '';
          const userIdSuccess = Number(user?.user_unique_id);
          setOwnerName(userName);
          setApiSuccessForOwner("true");
          setOwnerId(userIdSuccess);
        },
      (errorMessage) => {
         if(errorMessage.status === 400){
            setApiSuccessForOwner("false");
            setOwnerPhoneError("User Not found");
            setOwnerName("");
          }
        }
      ); }
    else {
      setIsFocusedOwner(false);
    }
  };
  const handleFocusDriver = () => {
      setIsFocusedDriver(true);
  };
  const handleBlurDriver = () => {
    if (driverPhoneNumber.length >= 1 && driverPhoneNumber.length < 10) {
      setDriverPhoneError('Phone number must be 10 digits');
      setDriverName(''); 
      setIsFocusedDriver(false); 
      setDriver(0);
      setApiSuccessForDriver("false");
      setErrorClient("");
      setSelectedClient([{ id: '', label: 'Select Client' }]);
    } else if (driverPhoneNumber.length == 10){
      setIsFocusedDriver(false); 
       getDriverName(driverPhoneNumber, 
      (success) => {
            setDriverPhoneError('');
            const user = success.message.user[0];
            const userName = user?.user_name || '';
            const userIdSuccess = Number(user?.user_unique_id);
            setDriverName(userName);
            setApiSuccessForDriver("true");
            setUserId(userIdSuccess);
            setDriverId(userIdSuccess);
            setDriver(0);
            const storedUserDetails = localStorage.getItem("userdetails");
            const userRole =storedUserDetails ? JSON.parse(storedUserDetails).role:null;;
            const vendorName = storedUserDetails ? JSON.parse(storedUserDetails).vendor_name:null;
            const vendorId = storedUserDetails ? JSON.parse(storedUserDetails).vendor_id:0;
            const clientName = storedUserDetails ? JSON.parse(storedUserDetails).client_name:null;
                 if (vendorName) {
                      setRole(userRole);
                      setVendorId(vendorId);
                      setVendorName(vendorName);
                      fetchClients(
                        (clients) => {
                          setClients(clients);
                        },
                       (errorMessage) => console.error(errorMessage)
                      );
                  }
                 else {
                    if (clientName) {
                        console.log(userRole);
                        setRole(userRole);
                        setClientName(clientName);
                        fetchVendors(
                          (vendors) => setVendors(vendors),
                          (errorMessage) => console.error(errorMessage)
                        );
                     }
               }
              },
              (errorMessage) => {
                  if(errorMessage.status === 400){
                     setApiSuccessForDriver("false");
                     setDriverPhoneError(" User not found")
                     setDriverName("");
                     setDriver(1);
                     setErrorClient("");
                  }
             }
      ); }
     else {
        setIsFocusedDriver(false);
       }
  };
  const handleVehicleNumberBlur = () => {
    validateVehicleNo(selectedValue.numberPlate);
  };
  const handleSubmit = () => {
    const { model, makeYear, numberPlate } = selectedValue; 
    setDriverPhoneError('');
    const clientId = selectedClient.length > 0 && selectedClient[0].id.trim() !== ''
        ? parseInt(selectedClient[0].id, 10)
        : 0;
   if (selectedClient.length === 0 || !selectedClient[0] || !selectedClient[0].id) {
      setErrorClient('Client is mandatory');
    }
    setError((prevError) => ({
         ...prevError,
         numberPlate: !selectedValue.numberPlate ? 'Vehicle number is required' : '',
         model: !selectedValue.model ? 'Model is required' : '',
         makeYear: !selectedValue.makeYear ? 'Make year is required' : '',
     }));
     if (!driverPhoneNumber) {
        setDriverPhoneError('Driver phone number is required');
    }
    if(driver===1)
      {
       setDriverPhoneError("User Not Found");
      }
   if (ownerPhoneNumber.length>0 && ownerName==='')
    { 
      toast.error("Please fix the errors");
      handleMakeYearBlur();
      handleVehicleNumberBlur();
      handleModel();
      return;
    }
   if (error.numberPlate === ''&& error.model === '' && error.makeYear === '' && ownerPhoneError === ''&& driverPhoneError === '' && driver===0 && selectedClient.length > 0 && selectedClient[0]?.id){
     addVehicle(
      model,
      makeYear,
      numberPlate,
      driverId,
      ownerId,
      fuelType,
       (response) => {
        const id = response.id;
        setVehicleId(id);
        toast.success('Vehicle created successfully ');
        console.log(clientId);
       if(userId && clientId) {
           addClientUserMapping(
              userId,
              clientId,
            (message) => {
              toast.success(message);
             },
            (errorMessage) => {
              toast.error(errorMessage);
               }
          ); }
          if(userId && vendorId) {
            console.log("hii");
             addVendorUserMapping(
                userId,
                vendorId,
              (message) => {
                toast.success(message);
               },
              (errorMessage) => {
                toast.error(errorMessage);
                 }
            ); }
         setIsDocumentModalOpen(true);
        },
    (errorMessage) => {
        toast.error(errorMessage);
    }
    );
   }
    else{
      toast.error("Please fix the errors");
      handleMakeYearBlur();
      handleVehicleNumberBlur();
      handleModel();
     }
  };
  const handleClientDropdownChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    const selectedOption = options.find(option => option.id === selectedId);
    if (selectedOption) {
      setSelectedClient([selectedOption]);
      setErrorClient('');
    } else {
      setSelectedClient([]);
    }
  };
 const options = clients.map((client) => ({
    id: client.id.toString(),
    label: client.name,
  }));
 const handleVendorDropdownChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value;
    const selectedOption = optionsVendor.find(optionVendor => optionVendor.id === selectedId);
    if (selectedOption) {
      setSelectedVendor([selectedOption]);
    } else {
      setSelectedVendor([]);
    }
 };
 const optionsVendor = vendors.map((vendor) => ({
    id: vendor.id.toString(),
    label: vendor.name,
  }));
  const handleModel = () => {
    console.log(error.model);
    if (error.model) {
      setError((prevError) => ({
        ...prevError,
        model: error.model,
      }));
    } 
  };
const handleMakeYearBlur = () => {
    const { makeYear } = selectedValue;
    if (makeYear.length > 0 && makeYear.length < 4) {
      setError((prevError) => ({
        ...prevError,
        makeYear: 'Please enter a 4-digit year.',
      }));
    } 
    else if(error.makeYear){
     setError((prevError) => ({
        ...prevError,
        makeYear: error.makeYear,
      }));
    }
  };
  
  return (
    <Dialog
      open={addVehicleModalOpen}
      onClose={handleCloseAddVehicle}
      fullWidth
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '500px', 
          height: 'auto', 
        }
      }}
    ><DialogTitle>
        <div style={{ margin: '-15px -20px 20px -20px' }}>
          <Heading title="Add Vehicle" closeIcon="true" onClose={handleCloseAddVehicle} />
        </div>
      </DialogTitle>
      <DialogContent>
      <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Vehicle Number</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="numberPlate"
              placeholder="Eg: KA 23 DE 1234"
              value={selectedValue.numberPlate}
              onBlur={handleVehicleNumberBlur}
              onChange={handleInputChange}
              error={!!error.numberPlate}
              helperText={error.numberPlate}
             />
          </Box>
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
          <p style={{ fontSize: '17px' }}>Model</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="model"
              onBlur={handleModel}
              value={selectedValue.model}
              onChange={handleInputChange}
              error={!!error.model}
              helperText={error.model}
            />
          </Box>
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '7px' }}>
          <p style={{ fontSize: '17px' }}>Make Year</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-24px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="makeYear"
              placeholder='YYYY'
              onBlur={handleMakeYearBlur}
              value={selectedValue.makeYear}
              onChange={handleInputChange}
              error={!!error.makeYear}
              helperText={error.makeYear}
            />
        </Box>
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '10px' }}>
          <p style={{ fontSize: '17px' }}>Fuel Type</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-26px' }}>
        <Dropdown 
            options={fuelOptions}
            selectedValue={fuelType}
            handleChange={handleFuelTypeChange}
            placeholder="Select Fuel"
            width="224px"
            height="41px"
           displayEmpty={true}
          >
            </Dropdown>
        </div>
       <div style={{ marginLeft: '-10px', marginTop: '7px' }}>
          <p style={{ fontSize: '17px' }}>Owner</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              type="text"
              name="ownerPhoneNumber"
              value={ !isFocusedOwner && ownerPhoneNumber.length == 10 && apiSucessForOwner === 'true'
              ? ownerName
              : ownerPhoneNumber
             }
              placeholder={ !isFocusedOwner && ownerPhoneNumber.length == 10 && apiSucessForOwner === 'true'
                ? ""
                : "Phone Number"
               }
              onChange={(e) =>  handlePhoneNumberChange(e)}
              onFocus={handleFocusOwner}
              onBlur={handleBlurOwner}
              error={!!ownerPhoneError}
              helperText={ownerPhoneError}
              style={{ width: '225px' }}
            />
          </Box>
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Driver</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-22px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              type="text"
              name="driverPhoneNumber"
              value={
                !isFocusedDriver && driverPhoneNumber.length == 10 && apiSucessForDriver === 'true'
                  ? driverName
                  : driverPhoneNumber
              }
              placeholder={
                !isFocusedDriver && driverPhoneNumber.length == 10 && apiSucessForDriver === 'true'
                  ? ""
                  : "Phone Number"
              }
              onChange={(e) => handlePhoneNumberChange(e)}
              onFocus={handleFocusDriver}
              onBlur={handleBlurDriver}
              error={!!driverPhoneError}
              helperText={driverPhoneError}
              style={{ width: '225px' }}
            />
            </Box>
        </div>
        {apiSucessForDriver=="true" && (!isFocusedDriver) && (
          <>
            {role === 'vendor_supervisor' || role === 'vendor_manager' ? (
              <>
            <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
              <p style={{ fontSize: '17px' }}>Client</p>
            </div>
            <div style={{ marginLeft: '200px', marginTop: '-23px' }}>
            <Dropdown 
                      options={options}
                      selectedValue={selectedClient.length > 0 ? selectedClient[0].id : ''}
                      handleChange={handleClientDropdownChange}
                      placeholder="Select Client"
                      displayEmpty={true}
                      width="225px"
                      height="43px"
                      searchable={false}
                      error={!!errorClient}
                      helperText={errorClient}
                    >
                    </Dropdown>
            </div>
            <div style={{ marginLeft: '-10px', marginTop: errorClient ? '18px' : '10px' }}>
              <p style={{ fontSize: '17px' }}>Vendor</p>
            </div>
            <div 
              style={{ marginLeft: '200px', marginTop: errorClient ? '-19px' : '-28px' 
              }}>
            <Box display="flex" alignItems="center">
                <TextField
                 variant="outlined"
                 size="small"
                 type="text"
                 name="vendorName"
                 disabled
                value={vendorName} 
                style={{ width: '225px' }}
                 ></TextField>
              </Box>
            </div>
            </>
         ) : role === 'client_supervisor' || role === 'client_manager' ? (
          <>
         <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
              <p style={{ fontSize: '17px' }}>Vendor</p>
            </div>
            <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
            <Dropdown 
                      options={optionsVendor}
                      selectedValue={selectedVendor.length > 0 ? selectedVendor[0].id : ''}
                      handleChange={handleVendorDropdownChange}
                      placeholder="Select Vendor"
                      width="220px"
                      height="43px"
                    >
                    </Dropdown>
            </div>
            <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
              <p style={{ fontSize: '17px' }}>Client</p>
            </div>
            <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
            <Box display="flex" alignItems="center">
                <TextField
                 variant="outlined"
                 size="small"
                 type="text"
                 name="clientName"
                 value={clientName} 
                 disabled
                 style={{ width: '225px' }}
                />
              </Box>
            </div>
        </>
    ) : (
      <p>User role is not vendor_supervisor, vendor_manager, client_supervisor, or client_manager</p>
    )}
    </>
        )}
    </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ marginLeft: '180px' }}>
          <Button className='custom-button' onClick={handleSubmit}>Add</Button>
        </div>
      
         <div style={{ marginRight: '300px' }}>
          <Button className='custom-button' onClick={handleCancel}>Cancel</Button>
        </div>
        {vehicleId !== null && (
         <AddDocumentModal isDocumentOpen={isDocumentModalOpen} handleClose={handleClose} vehicleId={vehicleId} />
        )}
         </DialogActions>
    </Dialog>
  );
};

export default AddVehicleModal;
