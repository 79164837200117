import axios, { AxiosResponse, AxiosError } from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const storedUserDetails = localStorage.getItem("userdetails");
const token = storedUserDetails ? JSON.parse(storedUserDetails).token.replace(/^"(.*)"$/, '$1') : null;

interface ErrorResponse {
  status: number;
  message: string;
}

export const getDrivers = (
  driver_leads: string | null,
  blacklist: string | null,
  per_page_records: number,
  page_number: number,
  left: string | null,
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: string, statusCode?: number) => void 
) => {
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(`${global.api_url}/api/drivers`, {
      params: { driver_leads, blacklist, per_page_records, page_number, left },
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
      }
    })
    .catch((error: AxiosError) => {
      const errorData = error.response?.data as ErrorResponse | undefined;
      const errorMessage = errorData?.message || "Unknown Error";
      const statusCode = error.response?.status;
      _errorCallBack(errorMessage, statusCode);
    });
};

export const addNonExistingDriver = (
  driver_users_id: number | null, 
  driver_leads_id: number | null,  
  remarks: string | null,          
  status: string | null,           
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`, 
    'Content-Type': 'application/json',
  };
  const payload = {
    driver_users_id, 
    driver_leads_id,
    remarks,
    status,
  };
  axios
    .post(`${global.api_url}/api/drivers_non_existing`, payload, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
        
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        localStorage.clear();
      } 
    });
};

export const deleteNonExistingDriver = (
  driver_non_existing_id: number,              
  _successCallBack: (response: any) => void,    
  _errorCallBack: (errMessage: any) => void,     
) => {
  const headers = {
    Authorization: `Bearer ${token}`,           
    'Content-Type': 'application/json',         
  };
  axios
    .delete(`${global.api_url}/api/drivers_non_existing`, {
      params: { driver_non_existing_id },
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);         
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);     
      } else if (error.response?.status === 401) {
        localStorage.clear();                  
      } else {
        toast.error('Server Error');             
      }
    });
};

export const addLeads = (
  name: string | null, 
  pincode: string | null,  
  phone: string | null,          
  email: string | null,           
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`, 
    'Content-Type': 'application/json',
  };
  const payload = {
    name,
    pincode,
    phone,
    email,
  };
  axios
    .post(`${global.api_url}/api/drivers_leads`, payload, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);
        toast.success('Non-existing driver added successfully');
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);
      } else if (error.response?.status === 401) {
        _errorCallBack(error.response.data); 
      } 
    });
};

export const deleteDriverLead = (
  driver_lead_id: number,               
  _successCallBack: (response: any) => void,    
  _errorCallBack: (errMessage: any) => void     
) => {
  const headers = {
    Authorization: `Bearer ${token}`,    
    'Content-Type': 'application/json',  
  };
  axios
    .delete(`${global.api_url}/api/drivers_leads`, {  
      params: { driver_lead_id },  
      headers: headers,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data);  
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data);  
      } else if (error.response?.status === 401) {
        localStorage.clear();  
      } else {
        toast.error('Server Error');  
      }
    });
};

export const editDriver = (
  name: string | null,              
  email: string | null,            
  whatsapp_number: string | null,   
  country_code_whatsapp: string | null,  
  language: string | null, 
  user_id:number,         
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`, 
    'Content-Type': 'application/json',
  };

  const payload = {
    name, 
    email, 
    whatsapp_number, 
    country_code_whatsapp, 
    language,
    user_id,
  };

  axios
    .put(`${global.api_url}/api/edit_user`, payload, { headers })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data); 
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data); 
      } else if  (error.response?.status === 401){
        localStorage.clear();
      }
      else{
        toast.error("Server Error");
      }
    });
};

export const editDriverLead = (
  driver_lead_id: string,           
  name: string | null,              
  pincode: string | null,            
  phone: string | null,             
  email: string | null,           
  _successCallBack: (response: any) => void,
  _errorCallBack: (errMessage: any) => void,
) => {
  const headers = {
    Authorization: `Bearer ${token}`,  
    'Content-Type': 'application/json',
  };

  const payload = {
    name, 
    pincode, 
    phone, 
    email,  
  };

  axios
    .put(`${global.api_url}/api/drivers_leads/${driver_lead_id}`, payload, { headers }) 
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        _successCallBack(response.data); 
      }
    })
    .catch((error: AxiosError) => {
      console.log('Error caught:', error);
      if (error.response?.status === 400) {
        _errorCallBack(error.response.data); 
      } else if (error.response?.status === 401) {
        localStorage.clear(); 
      } 
      else{
        toast.error("Server Error");
      }
    });
};
