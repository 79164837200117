import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, Tooltip ,TextField} from '@mui/material';
import Heading from '../../Components/Heading/heading.tsx';
import { getDocuments} from './action.ts';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface DocumentsModal {
  open: boolean;
  onClose: () => void;
  vehicle: any;
}

const DocumentsModal: React.FC<DocumentsModal> = ({ open, onClose, vehicle }) => {
    const [documentList, setDocumentList] = useState<any[]>([]);
    const [getInsuranceDate, setGetInsuranceDate] = useState<string | null>(null);
    const [getFitnessDate, setGetFitnessDate] = useState<string | null>(null);
    useEffect(() => {
        if (vehicle?.id && open) {
          getDocuments(
            vehicle.id,
            (response: any) => {
              const documentDetails = response.message?.vehicle_documents || [];
              setDocumentList(documentDetails);
              const insuranceDoc = documentDetails.find(doc => doc.document_name === 'insurance' && doc.status === 'uploaded');
          const fitnessDoc = documentDetails.find(doc => doc.document_name === 'fitness certificate' && doc.status === 'uploaded');
            if (insuranceDoc) {
              setGetInsuranceDate(insuranceDoc.expiry);
            }
            if (fitnessDoc) {
              setGetFitnessDate(fitnessDoc.expiry);
            }
          },
          (errMessage: any) => {
            toast.error(errMessage);
          }
        );
      }
  },[vehicle?.id, open]);
    useEffect(() => {
      if (!open) {
        setGetInsuranceDate(null);
        setGetFitnessDate(null);
      }
    }, [open]);
  const handleIconClick = (type: string, action: string) => {
    if (action === 'view') {
      const document = documentList.find(doc => doc.document_name.replace(' ', '').toLowerCase() === type.toLowerCase() && doc.status === 'uploaded');
      if (document) {
        const url = document.location;
        window.open(`${global.documents_image_url}${url}`, '_blank');
      } else {
        toast.error('Document not found.');
      }
    }
  };

  const renderFileInput = (label: string, type: string, expiryDate: string | null) => {
    const document = documentList.find(doc => doc.document_name.replace(' ', '').toLowerCase() === type.toLowerCase() && doc.status === 'uploaded');
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p style={{ marginRight: '10px', width: '180px' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
          />
          {document && (
            <Tooltip title="View File">
              <span
                style={{ cursor: 'pointer', color: 'blue', marginRight: '10px' }}
                onClick={() => handleIconClick(type, 'view')}
              >
                View
              </span>
            </Tooltip>
          )}
        </div>
        {expiryDate && (
          <TextField
            value={expiryDate}
            InputProps={{ readOnly: true }}
            variant="outlined"
            size="small"
            style={{ width: '150px' }}
          />
        )}
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: { width: '500px', height: 'auto' } }}>
      <DialogTitle>
        <div style={{ margin: '-13px -24px 20px -24px' }}>
          <Heading title='Documents' closeIcon="true" onClose={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        {renderFileInput('Registration Certificate:', 'registrationcertificate', null)}
        {renderFileInput('Pollution Certificate:', 'pollutioncertificate', null)}
        {renderFileInput('Fitness Certificate:', 'fitnesscertificate', getFitnessDate)}
        {renderFileInput('Insurance:', 'insurance', getInsuranceDate)}
      </DialogContent>
    </Dialog>
  );
};

export default DocumentsModal;
