import React,{useState,useRef, useEffect} from 'react';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import AddLeadModal from './add-lead-modal.tsx';
import '../../Components/Button/button-style.scss';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import {getDrivers , deleteDriverLead} from './action.ts';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description'; 
import EditIcon from '@mui/icons-material/Edit';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationPopupModal from './confirmation-popup-modal.tsx';
import EditDriverLeadModal from './edit-lead-modal.tsx';

const LeadTab: React.FC = () => {
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [driversList, setDriversList] = useState<any>();
    const [selectedDriver, setSelectedDriver] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [onSearch, setOnSearch] = useState('');
    const [isAddLeadOpen, setIsAddLeadOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState<boolean>(false);  
    const [status, setStatus] = useState<string>('');  
    const [remarks, setRemarks] = useState<string>('');
    const [driverLeadId , setDriverLeadId] = useState<number>(0);
    const [remarksList, setRemarksList] = useState<{ [key: number]: string }>({});
    const [remarksToPass, setRemarksToPass] = useState<string>('');
    const [openModal, setOpenModal] = useState(false);
    const [leadId , setLeadId] = useState<number>(0);
    const showEntries = [
        {id:'10',  label:'10'},
        { id: '20', label: '20' },
        { id: '50', label: '50' },
        { id: '100', label: '100' },
      ];
      const handleCloseModal = () => {
        setOpenModal(false);
    };
    const [selectedLeadData, setSelectedLeadData] = useState({
      name: '',
      pincode: '',
      phone: '',
      whatsapp_number: '',
      email: '',
    });
    const handleEditClick = (rowData: any,id) => {
      setSelectedLeadData(rowData);
      setOpenModal(true);
      setLeadId(id);
    };
    const handleCloseAddLead = () => {
        setIsAddLeadOpen(false);
      };
      const handleClosePopUp = () => {
        setOpenDialog(false);
      };
    const handleDeleteClick = (id,remarks ) => {
        const driverLeadId = id;
        setDriverLeadId(id);
        setRemarksToPass(remarks);
        console.log(remarks);
        if ( driverLeadId ) {
            deleteDriverLead(
                driverLeadId,
                 (response) => {
                 setOpenDialog(true);
            toast.success('Driver lead deactivated successfully');
            }, (error) => {
            toast.error(error);
          });
        }
      };
    const updateField = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        if (!isNaN(Number(value))) {
            setPageSize(Number(value));
            setPage(0);
        }
    };
    const number = 1;
    const stringValue = number.toString();
    useEffect(() => {
        getDrivers(
          stringValue,
            null,
            pageSize,
            page,
            null,
            (response: any) => {
                console.log('Full Response:', response);
                const driverDetails = response.driver_leads || [];
                setDriversList(driverDetails);
                console.log(driverDetails);
               },
            (errMessage: any, statusCode?: number) => {
                if (statusCode === 400) {
                  toast.error(errMessage);
                  console.log(errMessage);
                } else if (statusCode === 401) {
                  toast.error(errMessage);
                } else if (statusCode === 402) {
                  toast.error(errMessage);
                } else if (statusCode === 404) {
                  toast.error(errMessage);
                } else {
                  toast.error(errMessage);
                }
              }
        );
    }, [page, pageSize]);
    const handleClickOpen = () => {
        setIsAddLeadOpen(true);
      };
      const handleRemarksInput = (event: React.ChangeEvent<HTMLInputElement>, rowId: number) => {
        const { value } = event.target;
        setRemarksList((prevRemarks) => ({
          ...prevRemarks,
          [rowId]: value, 
        }));
      };
      const handleRemarksChange = (value: string, rowId: number, field: string) => {
        console.log(`Updating remarks for row ${rowId}:`, value);
      };
    const columns: GridColDef[] = [
        { field: 'sl_no', headerName: 'Sl No', width: 100, headerAlign: 'center' },
        { field: 'name', headerName: 'Name', flex: 1, headerAlign: 'center' },
        { field: 'pincode', headerName: 'Pincode', flex: 1, headerAlign: 'center' },
        { field: 'phone', headerName: 'Mobile Number', flex: 1, headerAlign: 'center' },
        { field: 'whatsapp_number', headerName: 'WhatsApp Number', flex: 1, headerAlign: 'center' },
        { field: 'email', headerName: 'Email', flex: 1, headerAlign: 'center' },
        {
            field: 'remarks',
            headerName: 'Remarks',
            flex: 1,
            headerAlign: 'center',
            editable: true, 
            renderCell: (params) => {
              const value = remarksList[params.row.id] ?? '';
              return (
                <div
                  style={{
                    border: '1px solid grey',
                    borderRadius: '5px',
                    outline: 'none',
                    boxShadow: 'none',
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                  }}
                >
                  {value}
                </div>
              );
            },
            renderEditCell: (params: GridRenderCellParams) => (
              <input
                type="text"
                value={remarksList[params.row.id] ?? ''} 
                onChange={(e) => handleRemarksInput(e, params.row.id)} 
                onBlur={() => handleRemarksChange(remarksList[params.row.id], params.row.id, 'remarks')} 
                style={{
                  border: '1px solid black',
                  outline: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  height: '100%',
                }}
              />
            ),
          },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100, headerAlign:'center',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    gap: '8px',
                  }}
                >
                <IconButton color="primary" onClick={() => handleDocumentsClick(params.row)} >
                <DescriptionIcon /> 
              </IconButton>
              <IconButton color="primary" onClick={() => handleEditClick(params.row,params.row.id)}>
                <EditIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => handleDeleteClick(params.row.id,remarksList[params.row.id])}>
              <DeleteIcon />
              </IconButton>
            </div>
              );
            },
          }
    ];
    const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage - 1);
      };
    const rows = driversList ? driversList.map((drivers, index) => ({
        id: drivers.id,
        sl_no: (page * pageSize) + index + 1,
        name: drivers.name,
        phone: drivers.phone,
        whatsapp_number: drivers.whatsapp_number,
        email : drivers.email,
        pincode : drivers.pincode,
    })) : [];

    return (
        <>
            <div style={{ marginLeft: "-18px", marginBottom: "20px", gap: '10px', display: "flex", alignItems: "center" }}>
                Show Entries:
                <Dropdown
                    options={showEntries}
                    selectedValue={pageSize.toString()}
                    handleChange={(event) => updateField(event)}
                    width='90px'
                    height='30px'
                />
                <Box display="flex" alignItems="center" style={{ marginLeft: 230 }}>
                    <TextField
                        className="styled-textfield"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search..."
                    />
                </Box>
                <Button className='custom-button' onClick={() => setOnSearch(searchTerm)}>
                    Search
                </Button>
                
                <div style={{ marginLeft: '360px', marginTop: '-10px' }}>
                    <Button className='custom-button' onClick={handleClickOpen}>
                        Add Leads
                    </Button>
                    <AddLeadModal addLeadModalOpen={isAddLeadOpen} handleCloseAddLead={handleCloseAddLead} />   
            </div>
            </div>
            <div className="table-container custom-data-grid" style={{ marginLeft: '-18px', marginTop: '10px', marginRight: '20px', position: 'relative', height: '500px', overflowY: 'auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={totalRecords}
                    autoHeight={false}
                    disableRowSelectionOnClick
                    disableColumnResize
                    hideFooterPagination
                />
            </div>
            <div style={{ marginLeft: '50%', marginTop: '8px' }}>
                <Newpagination
                    rows={totalRecords}
                    pageSize={pageSize}
                    page={page}
                    handlePageChange={handlePageClick}
                />
            </div>
            <ConfirmationPopupModal open={openDialog} handleClose={handleClosePopUp} driverLeadId={driverLeadId} remarksToPass={remarksToPass}/>
            <EditDriverLeadModal
                open={openModal}
                onClose={handleCloseModal}
                driverData={selectedLeadData}
                leadId = {leadId}
            />
       
        </>
    );
};

export default LeadTab;
