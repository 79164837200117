import React, {useState, useEffect} from 'react';
import Heading from '../../Components/Heading/heading.tsx';
import '../../Components/Button/button-style.scss';
import {Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, SelectChangeEvent, Box} from '@mui/material';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import {toast} from 'react-toastify';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import AddDocumentModal from './add-documents-modal.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';

interface AddBlacklistModalProps {
  addBlacklistModalOpen: boolean;
  handleCloseAddBlacklist: () => void;
}

const AddBlacklistModal = ( {addBlacklistModalOpen, handleCloseAddBlacklist} : AddBlacklistModalProps) => {
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
    const [driverName, setDriverName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [mobileNumber , setMobileNumber] = useState<string>('');
    const [mobileNumberError , setMobileNumberError] = useState<string>('');
    const [whatsAppNumber , setWhatsAppNumber] = useState<string>('');
    const [whatsAppNumberError , setWhatsAppNumberError] = useState<string>('');
    const handleSubmit = () => {
    };
    const handleCancel = () =>{};
    const handleClose = () => {
    };
    const handleDriverNameChange = (event) => {
      const { value } = event.target;
      setDriverName(value);
      if (value === '') {
        setNameError('');
      } else if (validateDriverName(value)) {
        setNameError('');
      } else {
        setNameError('Charcters are allowed');
      }
    };
    const handleEmailChange = (event) => {
      const { value } = event.target;
      setEmail(value);
      setEmailError('');
    };
   const handleEmailBlur = () => {
      if (email === '') {
        setEmailError('');
      } else if (validateEmail(email)) {
        setEmailError('');
      } else {
        setEmailError('Please enter a valid email address.');
      }
    };
    const handlePhoneNumberChange = (event) => {
      const { name, value } = event.target;
      if(name==='mobileNumber'){
        setMobileNumber(value);
      }
      if(name==='whatsAppNumber'){
        setWhatsAppNumber(value);
      }
      if(value===''){
      if (name==='mobileNumber' ) {
        setMobileNumberError("");
       } 
       if(name==='whatsAppNumber')
      {
        setWhatsAppNumberError("");
      }
      return;
    }
      const { errorMessage } = validatePhoneNumber(value);
      if (name==='mobileNumber' ) {
        setMobileNumberError(errorMessage);
      } 
       if(name==='whatsAppNumber')
      {
        setWhatsAppNumberError(errorMessage);
      }
    };
     
  return (
    <Dialog
      open={addBlacklistModalOpen}
      onClose={handleCloseAddBlacklist}
      fullWidth
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '500px', 
          height: 'auto', 
        }
      }}
    ><DialogTitle>
        <div style={{ margin: '-15px -20px 20px -20px' }}>
          <Heading title="Add Blacklist" closeIcon="true" onClose={handleCloseAddBlacklist} />
        </div>
      </DialogTitle>
      <DialogContent>
      <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Driver Name</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
          <TextField
              variant="outlined"
              size="small"
              name="driverName"
              value={driverName}
              onChange={handleDriverNameChange}
              error={!!nameError}
              helperText={nameError}
         />
          </Box>
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
          <p style={{ fontSize: '17px' }}>Location</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="location"
            />
          </Box>
        </div>
       <div style={{ marginLeft: '-10px', marginTop: '7px' }}>
          <p style={{ fontSize: '17px' }}>Mobile Number</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
        <TextField
              variant="outlined"
              size="small"
              type="text"
              name="mobileNumber"
              value={ mobileNumber}
              onChange={(e) =>  handlePhoneNumberChange(e)}
              error={!!mobileNumberError}
              helperText={mobileNumberError}
              style={{ width: '225px' }}
            />
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>WhatsApp Number</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-22px' }}>
          <Box display="flex" alignItems="center">
            <TextField
               variant="outlined"
               size="small"
               type="text"
               name="whatsAppNumber"
               value={ whatsAppNumber}
               onChange={(e) =>  handlePhoneNumberChange(e)}
               error={!!whatsAppNumberError}
               helperText={whatsAppNumberError}
               style={{ width: '225px' }}
            />
            </Box>
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Email</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
              <TextField
                variant="outlined"
                size="small"
                name="email"
                value={email}
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                error={!!emailError}
                helperText={emailError}
             />
          </Box>
        </div>
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ marginLeft: '180px' }}>
          <Button className='custom-button' onClick={handleSubmit}>Add</Button>
        </div>
        <div style={{ marginRight: '300px' }}>
          <Button className='custom-button' onClick={handleCancel}>Cancel</Button>
        </div>
        </DialogActions>
    </Dialog>
  );
};

export default AddBlacklistModal;
