import React, { useState } from 'react';
import NavBar from '../../Components/Navbar/sidenav.tsx';
import Header from '../../Components/Header/header.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import { TextField, Box, Button, IconButton, Tabs, Tab, Typography } from '@mui/material';
import '../../Components/Button/button-style.scss';
import DriversTab from './drivers-tab.tsx';
import LeadTab from './lead-tab.tsx';
import LeftTab from './left-tab.tsx';
import BlacklistTab from './blacklist-tab.tsx';

interface DriversProps {}

const Drivers: React.FC<DriversProps> = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [value, setValue] = useState(0);
  const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
  };
  const handleClick = () => {
    console.log(`Searching for: ${searchTerm}`);
  };
  const handleClose = () => {
    console.log('Closing');
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
       setValue(newValue);
  };
  const renderTabContent = (index: number) => {
      switch (index) {
             case 0:
                  return <DriversTab />;
              case 1:
                  return <LeadTab/>;
              case 2:
                   return <BlacklistTab />;
              case 3:
                   return <LeftTab/>; 
              default:
                   return <DriversTab />;
               }
  };

  return (
    <>
      <Header />
      <div style={{ marginLeft: '180px', marginTop: '110px', marginRight: '20px' }}>
          <Heading title="Drivers" closeIcon="false" onClose={handleClose} />
      </div>
      <div style={{ marginLeft: '210px', marginTop: '13px' ,marginRight:'40px' }}>
           <Tabs
              value={value}
              onChange={handleChange}
              aria-label="drivers tabs"
              sx={{
                backgroundColor: '#68E0CF',
                '& .MuiTabs-indicator': {
                  backgroundColor: '#68E0CF', 
                   },
                 }}
            >
            <Tab
                label="Drivers"
                sx={{
                    minWidth: '60px', 
                  color: value === 0 ? '#0e0d0dda' : '#0e0d0dda',
                  backgroundColor: value === 0 ? '#fff' : '#68E0CF',
                  textTransform: 'capitalize', 
                  '&.Mui-selected': {
                   color: '#0e0d0dda', 
                    },
                  '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
                }}
              />
              <Tab
                  label="Leads"
                  sx={{
                    minWidth: '60px',
                  color: value === 1 ? '#0e0d0dda' : '#0e0d0dda',
                  backgroundColor: value === 1 ? '#fff' : '#68E0CF',
                  textTransform: 'capitalize',  
                  '&.Mui-selected': {
                   color: '#0e0d0dda', 
                   },
                  '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
                  }}
              />
              <Tab
                label="BlackList"
                sx={{
                    minWidth: '100px',
                  color: value === 2 ? '#0e0d0dda' : '#0e0d0dda',
                  backgroundColor: value === 2 ? '#fff' : '#68E0CF',
                  textTransform: 'capitalize', 
                  '&.Mui-selected': {
                   color: '#0e0d0dda', 
                   },
                  '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
                }}
              />
              <Tab
                label="Left"
                sx={{
                  minWidth: '100px',
                  color: value === 3 ? '#0e0d0dda' : '#0e0d0dda',
                  backgroundColor: value === 3 ? '#fff' : '#68E0CF',
                  textTransform: 'capitalize', 
                  '&.Mui-selected': {
                   color: '#0e0d0dda', 
                   },
                  '&:hover': { backgroundColor: '#fff', color: '#0e0d0dda' },
                }}
              />
            </Tabs>
         <Box sx={{ p: 2 }}>
          {renderTabContent(value)}
         </Box>
      </div>
     <NavBar selected="Drivers" />
    </>
  );
};

export default Drivers;