import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, IconButton, Tooltip,Box,SelectChangeEvent } from '@mui/material';
import ConfirmationPopup from '../../Components/ConfirmationModal/confirmation-modal.tsx';
import Heading from '../../Components/Heading/heading.tsx';
import UploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import '../../Components/Table/table-style.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import {editDriver} from './action.ts';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';

interface DriverData {
  name: string;
  location: string;
  phone: string;
  whatsapp_number: string;
  email: string;
}

interface EditDriverModalProps {
  open: boolean;
  onClose: () => void;
  driverData: DriverData | null;
  driverId: number;
}

const EditDriverModal: React.FC<EditDriverModalProps> = ({ open, onClose,driverData ,driverId }) => {
    const [file, setFile] = useState<{ [key: string]: File | null }>({});
    const [icons, setIcons] = useState<{ [key: string]: 'upload' | 'view' }>({
        aadhar: 'upload',
        licence: 'upload',
    });
    const [countryCode, setCountryCode] = useState<string>('91');
    const [language, setLanguage] = useState<string>('EN');
    const [formValues, setFormValues] = useState({
      name: '',
      location: '',
      phone: '',
      whatsapp_number: '',
      email: '',
    });
    useEffect(() => {
      if (driverData) {
        setFormValues({
          name: driverData.name || '',
          location: driverData.location || '',
          phone: driverData.phone || '',
          whatsapp_number: driverData.whatsapp_number || '',
          email: driverData.email || '',
        });
      }
    }, [driverData]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [docTypeToDelete, setDocTypeToDelete] = useState<string | null>(null);
    const [emailError, setEmailError] = useState<string>('');
    const [nameError , setNameError] = useState<string>('');
    const [whatsAppNumberError , setWhatsAppNumberError] = useState<string>('');
    const handleIconClick = (type: string, action: 'view' | 'remove') => {
        if (action === 'remove') {
            setDocTypeToDelete(type);
            setOpenDeleteModal(true);
        } else if (action === 'view' && file[type]) {
           
        }
    };
    const handleConfirmDelete = () => {
        if (docTypeToDelete) {
            setFile(prev => ({ ...prev, [docTypeToDelete]: null }));
            setIcons(prev => ({ ...prev, [docTypeToDelete]: 'upload' }));
            setOpenDeleteModal(false);
            setDocTypeToDelete(null);
        }
    };
    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setDocTypeToDelete(null);
    };
    const handleSave = () => {
      console.log("Save button clicked");
      console.log("Form values:", formValues);
      editDriver(
        formValues.name, 
        formValues.email, 
        formValues.whatsapp_number, 
        countryCode, 
        language, 
        driverId,
        (response) => {
          toast.success(response.message); 
        },
        (error) => {
          console.error('Failed to update user:', error);
        }
      );
      onClose();
      setTimeout(() => {
        window.location.reload();
       }, 3000);
     };
    const handleClose = () => {
        onClose();
    };
    const handleNameChange = (event) => {
      const { value } = event.target;
      if (value === '') {
        setNameError('');
      } else if (validateDriverName(value)) {
        setNameError('');
      } else {
        setNameError('Charcters are allowed');
      }
      setFormValues(prev => ({
        ...prev,
        name: value,
      }));
    };
    const handleEmailChange = (event) => {
        const { value } = event.target;
        setEmailError('');
        setFormValues(prev => ({
          ...prev,
          email: value,
      }));
    };
    const handleEmailBlur = () => {
        if (formValues.email === '') {
          setEmailError('');
        } else if (validateEmail(formValues.email)) {
          setEmailError('');
        } else {
          setEmailError('Please enter a valid email address.');
        }
      };
    const handlePhoneNumberChange = (event) => {
        const { value } = event.target;
        if(value==='')
        {
          setWhatsAppNumberError("");
        }
        else{
        const { errorMessage } = validatePhoneNumber(value);
        setWhatsAppNumberError(errorMessage);}
        setFormValues(prev => ({
          ...prev,
          whatsapp_number: value,
      }));
      console.log(formValues.whatsapp_number);
    };  
    const maxFileSize = 1 * 1024 * 1024;
    const allowedFileTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const selectedFile = e.target.files?.[0];
        if (selectedFile) {
            if (selectedFile.size > maxFileSize) {
                toast.error('File size exceeds 1MB.');
                return;
            }
            if (!allowedFileTypes.includes(selectedFile.type)) {
                toast.error('Invalid file type.');
                return;
            }
            setFile(prev => ({ ...prev, [type]: selectedFile }));
            setIcons(prev => ({ ...prev, [type]: 'view' }));
        }
    };
    const renderFileInput = (label: string, type: string) => (
        <div>
          <p style={{  display: 'inline-block', width: '165px' }}>{label}</p>
          <input
            accept="*"
            style={{ display: 'none' }}
            id={`${type}-file`}
            type="file"
            onChange={(e) => handleFileChange(e, type)}
          />
          <label htmlFor={`${type}-file`}>
          <Tooltip title="Upload File">
              <IconButton color="primary" component="span">
                {icons[type] === 'upload' && <UploadIcon />}
              </IconButton>
            </Tooltip>
          </label>
          {icons[type] === 'view' && (
            <>
              <Tooltip title="View File">
                <span
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() => handleIconClick(type, 'view')}
                >
                  View
                </span>
              </Tooltip>
              <Tooltip title="Remove File">
                <IconButton sx={{ color: 'red' }} component="span" onClick={() => handleIconClick(type, 'remove')}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </div>
    );
      return (
        <Dialog open={open} onClose={handleClose} PaperProps={{ sx: { width: '800px', height: 'auto' } }}>
          <DialogTitle>
            <div style={{ margin: '-13px -24px 0px -24px' }}>
              <Heading title={`Edit Driver - ${formValues.phone}`}  closeIcon="true" onClose={handleClose} />
            </div>    
          </DialogTitle>
          <DialogContent>
          <div style={{ marginLeft: '40px', marginTop: '8px' }}>
          <TextField
          size='small'
          label="Name"
          size='small'
          name="name"
          value={formValues.name}
          onChange={(e) =>  handleNameChange(e)}
          error={!!nameError}
          helperText={nameError}
          style={{ width: '225px' }}
          />
          </div>
         <div style={{ marginLeft: '300px', marginTop: '-40px' }}>
         <TextField
          size='small'
          label="WhatsApp Number"
          name="whatsapp_number"
          value={formValues.whatsapp_number}
          onChange={(e) =>  handlePhoneNumberChange(e)}
          error={!!whatsAppNumberError}
          helperText={whatsAppNumberError}
          style={{ width: '225px' }}
          />
          </div>
          <div style={{ marginLeft: '40px', marginTop: '10px' }}>
        <TextField
          size='small'
          label="Email"
          name="email"
          value={formValues.email}
          onChange={handleEmailChange}
          onBlur={handleEmailBlur}
          error={!!emailError}
          helperText={emailError}
         />
         </div>
          <div style= {{marginLeft: '40px', marginTop:'30px'}}>
            {renderFileInput('Aadhar', 'aadhar')}
          </div>
          <div style= {{marginLeft: '300px', marginTop:'-20px'}}>
            {renderFileInput('Licence','licence')}
          </div>
         
          </DialogContent>
          <DialogActions  style={{ display: 'flex', justifyContent: 'center' }}>
            <Button className='custom-button' onClick={handleSave}>Save</Button>
            <Button className='custom-button' onClick={handleClose}>Cancel</Button>
          </DialogActions>
          <ConfirmationPopup
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    onConfirm={handleConfirmDelete}
                    title="Confirm Deletion"
                    message={`Are you sure you want to delete this ${docTypeToDelete?.replace('_', ' ')}?`}
                />
      </Dialog>
    );
  };
  export default EditDriverModal;