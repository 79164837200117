import React,{useState,useRef, useEffect} from 'react';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import Newpagination from '../../Components/Pagination/pagination.tsx';
import '../../Components/Button/button-style.scss';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { SelectChangeEvent } from '@mui/material/Select';
import { getDrivers, deleteNonExistingDriver } from './action.ts';
import IconButton from '@mui/material/IconButton';
import DescriptionIcon from '@mui/icons-material/Description'; 
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, TextField, Box } from '@mui/material';
import AddLeftModal from './add-left-modal.tsx';
import DeleteIcon from '@mui/icons-material/Delete';

const LeftTab: React.FC = () => {
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [driversList, setDriversList] = useState<any>();
    const [selectedDriver, setSelectedDriver] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [onSearch, setOnSearch] = useState('');
    const [isAddLeftOpen, setIsAddLeftOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [documentsModal, setDocumentsModal] = useState(false);
    const [remarksList, setRemarksList] = useState<{ [key: number]: string }>({});
    const showEntries = [
        {id:'10',  label:'10'},
        { id: '20', label: '20' },
        { id: '50', label: '50' },
        { id: '100', label: '100' },
      ];
      const handleCloseModal = () => {
        setOpenModal(false);
      };
      const handleCloseAddLeft = () => {
        setIsAddLeftOpen(false);
      };
      const handleDocumentsClick = (vehicle: any) => {
         setDocumentsModal(true);
      };
    const updateField = (event: SelectChangeEvent<string>) => {
        const value = event.target.value;
        if (!isNaN(Number(value))) {
            setPageSize(Number(value));
            setPage(0);
        }
    };
    const number = 1;
    const stringValue = number.toString();
    useEffect(() => {
      getDrivers(
          null,
          null,
          pageSize,
          page,
          stringValue,
          (response: any) => {
              console.log('Full Response:', response);
              const driverDetails = Array.isArray(response.left_drivers)
              ? response.left_drivers.map((driver: any) => {
                  const driverInfo = driver.driver_user || driver.driver_lead;
                  return {
                    id: driver.id,
                    name: driverInfo?.name,
                    phone: driverInfo?.phone,
                    whatsapp_number: driverInfo?.whatsapp_number, 
                    email: driverInfo?.email,
                    remarks: driver.remarks,
                  };
                })
              : [];
              setDriversList(driverDetails);
              console.log('Driver Details:', driverDetails);
              const initialRemarksList = driverDetails.reduce((acc, driver) => {
                acc[driver.id] = driver.remarks || ''; 
                return acc;
            }, {});
            setRemarksList(initialRemarksList); 
          },
          (errMessage: any) => {
              toast.error(errMessage);
          }
      );
  }, [page, pageSize]);
  const handleClickOpen = () => {
        setIsAddLeftOpen(true);
      };
  const handleEditClick = () => {
        setOpenModal(true);
      };
  const handleCloseDetailsModal = () => {
        setDocumentsModal(false);
      };
  const handleDeleteClick = (id :number) => {
        const driverLeadId = id; 
        console.log( driverLeadId );
        if ( driverLeadId ) {
            deleteNonExistingDriver(
                driverLeadId,
                 (response) => {
            toast.success('Driver lead deactivated successfully');
            window.location.reload();
          }, (error) => {
            toast.error(error);
          });
        } 
      };
      const handleRemarksInput = (e, driverId) => {
        setRemarksList((prevRemarksList) => ({
          ...prevRemarksList,
          [driverId]: e.target.value, 
        }));
      };
    const handleRemarksChange = (value: string, rowId: number, field: string) => {
        console.log(`Updating remarks for row ${rowId}:`, value);
      };
    const columns: GridColDef[] = [
        { field: 'sl_no', headerName: 'Sl No', width: 100, headerAlign: 'center' },
        { field: 'name', headerName: 'Name', flex: 1, headerAlign: 'center' },
        { field: 'location', headerName: 'Location', flex: 1, headerAlign: 'center' },
        { field: 'phone', headerName: 'Mobile Number', flex: 1, headerAlign: 'center' },
        { field: 'whatsapp_number', headerName: 'WhatsApp Number', flex: 1, headerAlign: 'center' },
        { field: 'email', headerName: 'Email', flex: 1, headerAlign: 'center' },
        {
          field: 'remarks',
          headerName: 'Remarks',
          flex: 1,
          headerAlign: 'center',
          editable: true, 
          renderCell: (params) => {
            return (
              <div
                style={{
                  border: '1px solid grey',
                  borderRadius: '5px',
                  outline: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  height: '100%',
                  boxSizing: 'border-box',
                }}
              >
                {params.row.remarks || ''} 
              </div>
            );
          },
          
          renderEditCell: (params: GridRenderCellParams) => (
            <input
              type="text"
              value={remarksList[params.row.id] ?? ''} 
              onChange={(e) => handleRemarksInput(e, params.row.id)} 
              onBlur={() => handleRemarksChange(remarksList[params.row.id], params.row.id, 'remarks')} 
              style={{
                border: '1px solid black',
                outline: 'none',
                boxShadow: 'none',
                width: '100%',
                height: '100%',
              }}
            />
          ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100, headerAlign:'center',
            renderCell: (params: GridRenderCellParams) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    gap: '8px',
                  }}
                >
                <IconButton color="primary" onClick={() => handleDocumentsClick(params.row)} >
                <DescriptionIcon /> 
              </IconButton>
              <IconButton color="primary" onClick={() => handleEditClick(params.row)}>
                <EditIcon />
              </IconButton>
              <IconButton color="primary" onClick={() => handleDeleteClick(params.row.id)}>
              <DeleteIcon />
              </IconButton>
            </div>
              );
            },
          },
    ];
    const handlePageClick = (event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage - 1);
    };
    const rows = driversList ? driversList.map((drivers, index) => ({
        id: drivers.id,
        sl_no: (page * pageSize) + index + 1,
        name: drivers.name,
        phone: drivers.phone,
        whatsapp_number: drivers.whatsapp_number,
        email : drivers.email,
        remarks : drivers.remarks,
    })) : [];

    return (
        <>
            <div style={{ marginLeft: "-18px", marginBottom: "20px", gap: '10px', display: "flex", alignItems: "center" }}>
                Show Entries:
                <Dropdown
                    options={showEntries}
                    selectedValue={pageSize.toString()}
                    handleChange={(event) => updateField(event)}
                    width='90px'
                    height='30px'
                />
                <Box display="flex" alignItems="center" style={{ marginLeft: 230 }}>
                    <TextField
                        className="styled-textfield"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search..."
                    />
                </Box>
                <Button className='custom-button' onClick={() => setOnSearch(searchTerm)}>
                    Search
                </Button>
                
                <div style={{ marginLeft: '360px', marginTop: '-10px' }}>
                    <Button className='custom-button' onClick={handleClickOpen}>
                        Add Left
                    </Button>
                    <AddLeftModal addLeftModalOpen={isAddLeftOpen} handleCloseAddLeft={handleCloseAddLeft} />   
            </div>
            </div>
            <div className="table-container custom-data-grid" style={{ marginLeft: '-18px', marginTop: '10px', marginRight: '20px', position: 'relative', height: '500px', overflowY: 'auto' }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    rowCount={totalRecords}
                    autoHeight={false}
                    disableRowSelectionOnClick
                    disableColumnResize
                    hideFooterPagination
                />
            </div>
            <div style={{ marginLeft: '50%', marginTop: '8px' }}>
                <Newpagination
                    rows={totalRecords}
                    pageSize={pageSize}
                    page={page}
                    handlePageChange={handlePageClick}
                />
            </div>
      </>
    );
};

export default LeftTab;
