export interface PhoneValidationResult {
    errorMessage: string;
  }
 export function validatePhoneNumber(value: string): PhoneValidationResult {
    let errorMessage = "";
    const hasSpecialCharacters = /[^0-9]/.test(value);
    const isValidLength = value.length >10;
    const startsWithValidDigit = /^[6-9]/.test(value);
    if (hasSpecialCharacters) {
      errorMessage = "Phone Number must be digits";
      return { errorMessage };
    }  if (!startsWithValidDigit  ) {
      errorMessage = "Phone Number must start with 6, 7, 8, or 9";
      return { errorMessage };
    }  if (isValidLength ) {
      errorMessage = "Phone Number must be 10 digits";
      return { errorMessage };
    }
    return { errorMessage };
  }
  