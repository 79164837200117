export const validateMakeYear = (value) => {
    if (!value) {
        return '';
    }
    const hasNonDigits = /[^0-9]/.test(value);
    if (hasNonDigits) {
        return 'Year must contain only digits.';
    }
    if (value.length === 4) {
        const year = parseInt(value, 10);
        if (year <= 1999) {
            return 'Year must be greater than 1999.';
        } else if (year > new Date().getFullYear()) {
            return 'Year must be less than the current year.';
        }
    } else if (value.length > 4) {
        return 'Please enter a 4-digit year.';
    }
    return '';
};
