import React from 'react';
import {useState, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import '../../Components/Button/button-style.scss';
import Dropdown from '../../Components/DropDown/drop-down.tsx';
import {SelectChangeEvent , TextField, Box} from '@mui/material';
import { addNonExistingDriver} from './action.ts';
import { toast } from 'react-toastify';

interface ConfirmationPopupModalProps {
  open: boolean;
  handleClose: () => void;
  driverLeadId : number;
  remarksToPass : string;
}

const ConfirmationPopupModal: React.FC<ConfirmationPopupModalProps> = ({ open, handleClose, driverLeadId ,remarksToPass }) => {
   
    const statusOptions = [
        { id: 'blacklist', label: 'Blacklist'},
        { id: 'left', label: 'Left' },
        { id: 'others', label: 'Others' },
      ];
    const [status, setStatus] =  useState('');
    const [remarks, setRemarks] =  useState('');
    useEffect(() => {
        if (remarksToPass) {
          setRemarks(remarksToPass);
        }
    }, [remarksToPass, open]);
    const handleStatusChange = (event: SelectChangeEvent<string>) => {
        setStatus(event.target.value);
    };
    const handleRemarks = (event) => {
        const { value } = event.target;
       setRemarks(value);
    };
    const handleSave = () => {
        addNonExistingDriver(
            null, 
            driverLeadId,
            remarks,
            status, 
            (response) => {
                toast.success(`Driver lead added to ${status} successfully`);
            },
            (error) => {
                toast.error(`Error adding driver to ${status}: ${error}`);
            }
        );
       handleClose();
       window.location.reload();
    };
    return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <div style={{ marginLeft: '-10px', marginTop: '10px' }}>
          <p style={{ fontSize: '17px' }}>Status</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-26px' }}>
         <Dropdown 
            options={statusOptions}
            selectedValue={status}
            handleChange={handleStatusChange}
            placeholder="Select Status"
            width="224px"
            height="41px"
           displayEmpty={true}
          >
         </Dropdown>
        </div> 
        <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Remarks</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
          <TextField
              variant="outlined"
              size="small"
              name="remarks"
              value={remarks}
              onChange={handleRemarks}
          />
          </Box>
        </div>
      </DialogContent>
      <DialogActions>
        <div style={{ marginLeft: '100px', marginTop: '10px' }}>
           <Button className='custom-button' onClick={handleSave} color="primary">Confirm</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopupModal;
