import React, {useState, useEffect} from 'react';
import Heading from '../../Components/Heading/heading.tsx';
import '../../Components/Button/button-style.scss';
import {Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, SelectChangeEvent, Box} from '@mui/material';
import {toast} from 'react-toastify';
import {validatePhoneNumber} from '../../Utils/PhoneNumberValidation/phone-number-validation.tsx';
import {addLeads} from './action.ts';
import {validatePincode} from '../../Utils/PincodeValidation/pincode-validation.tsx';
import {validateDriverName} from '../../Utils/NameValidation/name-validation.tsx';
import {validateEmail} from '../../Utils/EmailValidation/email-validation.tsx';

interface AddLeadModalProps {
  addLeadModalOpen: boolean;
  handleCloseAddLead: () => void;
}

const AddLeadModal = ({ addLeadModalOpen, handleCloseAddLead }: AddLeadModalProps) => {
    const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
    const [name, setName] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [mobileNumber , setMobileNumber] = useState<string>('');
    const [mobileNumberError , setMobileNumberError] = useState<string>('');
    const [pincode , setPincode] = useState<string>('');
    const [pincodeError , setPincodeError] = useState<string>('');
    const resetState = () => {
      setName('');
      setNameError('');
      setEmail('');
      setEmailError('');
      setMobileNumber('');
      setMobileNumberError('');
      setPincode('');
    };
    const handleSubmit = () => {
        addLeads(
            name,
            pincode,
            mobileNumber,
            email,
              (response: any) => {
                 toast.success("Added Successfully");
                 
              },
              (errMessage: any) => {
                  toast.error(errMessage);
              }
          );
          handleCloseAddLead();
          resetState();
          window.location.reload();
    };
    const handleCancel = () =>{
      handleCloseAddLead();
      resetState();
    };
    const handleClose = () => {
      handleCloseAddLead();
    };
   
   const handlePincodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setPincode(value);
      const error = validatePincode(value);
      setPincodeError(error);
   };
   const handleNameChange = (event) => {
      const { value } = event.target;
      setName(value);
      if (value === '') {
        setNameError('');
      } else if (validateDriverName(value)) {
        setNameError('');
      } else {
        setNameError('Charcters are allowed');
      }
   };
  const handleEmailChange = (event) => {
      const { value } = event.target;
      setEmail(value);
      setEmailError('');
  };
  const handleEmailBlur = () => {
      if (email === '') {
        setEmailError('');
      } else if (validateEmail(email)) {
        setEmailError('');
      } else {
        setEmailError('Please enter a valid email address.');
      }
  };
  const handlePhoneNumberChange = (event) => {
      const { name, value } = event.target;
      if(name==='mobileNumber'){
        setMobileNumber(value);
      }
      if(value===''){
      if (name==='mobileNumber' ) {
        setMobileNumberError("");
       } 
      return;
    }
      const { errorMessage } = validatePhoneNumber(value);
      if (name==='mobileNumber' ) {
        setMobileNumberError(errorMessage);
      } 
  };
  const handlePincodeBlur = () => {
     if(pincode.length<6)
     {
      setPincodeError("Pincode contain 6 digits");
     }
  };  
    
  return (
    <Dialog
      open={addLeadModalOpen}
      onClose={handleCloseAddLead}
      fullWidth
      maxWidth={false}
      PaperProps={{
        sx: {
          width: '500px', 
          height: 'auto', 
        }
      }}
    ><DialogTitle>
        <div style={{ margin: '-15px -20px 20px -20px' }}>
          <Heading title="Add Lead" closeIcon="true" onClose={handleCloseAddLead} />
        </div>
      </DialogTitle>
      <DialogContent>
      <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}> Name</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
          <TextField
              variant="outlined"
              size="small"
              name="name"
              value={name}
              onChange={handleNameChange}
              error={!!nameError}
              helperText={nameError}
         />
          </Box>
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '8px' }}>
          <p style={{ fontSize: '17px' }}>Pincode</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
          <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="pincode"
              value = {pincode}
              onBlur={handlePincodeBlur}
              onChange={handlePincodeChange}
              error={!!pincodeError}
              helperText={pincodeError}
            />
          </Box>
        </div>
       <div style={{ marginLeft: '-10px', marginTop: '7px' }}>
          <p style={{ fontSize: '17px' }}>Mobile Number</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
        <TextField
              variant="outlined"
              size="small"
              type="text"
              name="mobileNumber"
              value={ mobileNumber}
              onChange={(e) =>  handlePhoneNumberChange(e)}
              error={!!mobileNumberError}
              helperText={mobileNumberError}
              style={{ width: '225px' }}
            />
        </div>
        <div style={{ marginLeft: '-10px', marginTop: '5px' }}>
          <p style={{ fontSize: '17px' }}>Email</p>
        </div>
        <div style={{ marginLeft: '200px', marginTop: '-25px' }}>
           <Box display="flex" alignItems="center">
            <TextField
              variant="outlined"
              size="small"
              name="email"
              value={email}
              onChange={handleEmailChange}
              onBlur={handleEmailBlur}
              error={!!emailError}
              helperText={emailError}
            />
          </Box>
         </div>
    </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ marginLeft: '180px' }}>
          <Button className='custom-button' onClick={handleSubmit}>Add</Button>
        </div>
       <div style={{ marginRight: '300px' }}>
          <Button className='custom-button' onClick={handleCancel}>Cancel</Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AddLeadModal;
