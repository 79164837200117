export const formatVehicleNo = (vehicleNo) => {
    if (!vehicleNo) {
        return { formatted: "", error: "" };
    }
    vehicleNo = vehicleNo.toUpperCase();
    const replacedSpace = vehicleNo.replace(/ /g, "");
    let errorMessage = '';
    if (replacedSpace.length >= 1 && !/^[A-Z]{1}/.test(replacedSpace)) {
        errorMessage = 'The first character must be an alphabet.';
    }
    if (replacedSpace.length >= 2 && !/^[A-Z]{2}/.test(replacedSpace)) {
        errorMessage = 'The second character must be an alphabet.';
    }
    if (replacedSpace.length >= 3 && !/^[A-Z]{2}\d{1}/.test(replacedSpace)) {
        errorMessage = 'The third character must be a digit.';
    }
    if (errorMessage) {
        return { formatted: replacedSpace, error: errorMessage };
    }
    const pattern = /^([A-Z]{2})(\d{1,2})([A-Z]{0,2}?)(\d{1,4})?$/;
    const match = replacedSpace.match(pattern);
    if (match) {
        const [, part1, part2, part3, part4] = match;
        const formattedPart2 = part2.length === 1 && part3 ? `0${part2}` : part2;
        let formatted = `${part1} ${formattedPart2}`;
        if (part3) {
            formatted += ` ${part3}`;
        }
        if (part4) {
            formatted += ` ${part4}`;
        }
        return { formatted, error: '' };
    } else {
        return { formatted: replacedSpace, error: '' };
    }
};

